export const COLOR_INITIAL = {
    COLOR_BACKGROUND_VERDER_AGUA: '#48c590',
    COLOR_BACKGROUND_BLANCO: "#ffffff",
    COLOR_BACKGROUND_VERDE_CLARO: "#e2f7ed",
    COLOR_BACKGROUND_AZUL_CLARO: "#4962c7",
    COLOR_BACKGROUND_GRIS: "#777777",
    COLOR_BACKGROUND_GRIS_CLARO: "#4f4f4f",
    COLOR_AMARILLO: "#efce22",
    HEADER_COLOR: "#000000",
    NEGRO: '#2b2a29'
}